import { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { handleImgDownload } from "../../../utils/utils";
import { Link, Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface Props {
    openPopUp: boolean;
    certificationPicture: string;
    onClose: () => void;
}

const InstagramPopUp = ({ openPopUp, onClose, certificationPicture }: Props) => {
    const { t } = useTranslation();

    const handleOnClose = () => {
        onClose();
    };

    return (
        <Dialog hideBackdrop open={openPopUp} onClose={() => handleOnClose()}>
            <DialogTitle>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    {t("certificate_validator.shareButton.instagram.share_dialog.title")}
                    <CloseIcon onClick={onClose} />
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <Typography>{t("certificate_validator.shareButton.instagram.share_dialog.subtheading")}</Typography>
                <Typography>
                    <ol>
                        <li>
                            {t("certificate_validator.shareButton.instagram.share_dialog.step1")}
                            <Link href="#" onClick={() => handleImgDownload(certificationPicture)}>
                                {t("certificate_validator.shareButton.instagram.share_dialog.link_tag")}
                            </Link>
                        </li>
                        <li>
                            {t("certificate_validator.shareButton.instagram.share_dialog.step2")}
                            <Link href="https://www.instagram.com/" target="_blank">
                                {t("certificate_validator.shareButton.instagram.share_dialog.link_tag")}
                            </Link>
                        </li>
                        <li>{t("certificate_validator.shareButton.instagram.share_dialog.step3")}</li>
                    </ol>
                </Typography>
            </DialogContent>
        </Dialog>
    );
};

export default InstagramPopUp;
