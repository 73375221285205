import React, { ReactElement } from "react";
import clsx from "clsx";

// Hooks & utils
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../../utils/customHooks";
import { getCertificateExpired } from "../../../utils/utils";

// Redux
import { useAppSelector } from "../../../redux/hooks";

// MUI components
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";

// MUI icons
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CloseIcon from "@material-ui/icons/Close";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "absolute",
            top: -4,
            right: 14,
            display: "flex",
            alignItems: "center",
        },
        wrapper: {
            margin: theme.spacing(1),
            position: "relative",
            borderRadius: "50%!important",
            boxShadow: "0px 3px 5px -1px rgba(0,0,0,20%)",
            backgroundColor: "transparent",
        },
        fabIconRoot: {
            height: 20,
            width: 20,
            color: "white",
        },
        fabRoot: {
            height: 36,
            width: 36,
            backgroundColor: "#b7b7b7",
            "&:hover": {
                backgroundColor: "#b7b7b7",
            },
            "&:focus": {
                outline: 0,
            },
        },
        buttonSuccess: {
            backgroundColor: green[500],
            "&:hover": {
                backgroundColor: green[700],
            },
        },
        buttonError: {
            backgroundColor: theme.palette.grey[600],
            "&:hover": {
                backgroundColor: theme.palette.grey[800],
            },
        },
        fabProgress: {
            color: green[500],
            position: "absolute",
            top: -3,
            left: -3,
            zIndex: 1,
        },
        buttonProgress: {
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
    })
);

interface Props {
    expiration_date?: string;
}

export default function CertificateValidationIcon(props: Props): ReactElement {
    // Misc hooks
    const classes = useStyles();
    const { t } = useTranslation();
    const [windowWidth] = useWindowSize();
    // Redux state
    const { pdfValidity, certificateDetails } = useAppSelector((state) => ({
        pdfValidity: state.certificate_validator.pdfValidity,
        certificateDetails: state.certificate_validator.certificateDetails,
    }));
    const isPdfCertificate = !!certificateDetails?.key_vault_certificate_name;
    // State hooks
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    // Ref hooks
    const timer = useRef<number>();
    const timerDelay = useRef<number>();

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success || pdfValidity === "valid",
        [classes.buttonError]: getCertificateExpired(props.expiration_date) && !loading,
    });

    useEffect(() => {
        verifyCertificate();
        return () => {
            clearTimeout(timer.current);
            clearTimeout(timerDelay.current);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const verifyCertificate = () => {
        if (!loading && !isPdfCertificate) {
            timerDelay.current = window.setTimeout(() => {
                setSuccess(false);
                setLoading(true);
                timer.current = window.setTimeout(() => {
                    setSuccess(true);
                    setLoading(false);
                }, Math.random() * (3200 - 2700) + 2700);
            }, 200);
        }
    };

    const handleSnackBarOpen = (event: React.SyntheticEvent | React.MouseEvent) => {
        // open only on mobile, when text next to icon is not shown (480px)
        if (windowWidth < 800) setSnackbarOpen(true);
    };

    const handleSnackBarClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <div className={classes.root}>
            {windowWidth > 800 && (
                <div>
                    {isPdfCertificate ? (
                        <p className="cv-validation-text">
                            {pdfValidity === "valid" &&
                                t("certificate_validator.found.verification_success", "Validation successful")}
                            {pdfValidity === "invalid" &&
                                t("certificate_validator.found.verification_invalid", "Validation of PDF failed")}
                            {pdfValidity === "not_checked" &&
                                t(
                                    "certificate_validator.found.verification_not_checked",
                                    "Upload the PDF to validate this certificate"
                                )}
                        </p>
                    ) : (
                        <p className="cv-validation-text">
                            {success
                                ? getCertificateExpired(props.expiration_date)
                                    ? t("certificate_validator.found.verification_expired", "Status: Expired")
                                    : t("certificate_validator.found.verification_success", "Validation successful")
                                : loading
                                ? t("certificate_validator.found.verification_processing", "Validating")
                                : ""}
                        </p>
                    )}
                </div>
            )}
            <div className={classes.wrapper} onClick={handleSnackBarOpen}>
                {isPdfCertificate ? (
                    <IconButton
                        className={buttonClassname}
                        classes={{ root: classes.fabRoot }}
                        size="small"
                        aria-label="save"
                        color="primary"
                        onClick={undefined}
                    >
                        {pdfValidity === "valid" ? (
                            <VerifiedUserOutlinedIcon classes={{ root: classes.fabIconRoot }} />
                        ) : (
                            <ErrorOutlineIcon classes={{ root: classes.fabIconRoot }} />
                        )}
                    </IconButton>
                ) : (
                    <>
                        <IconButton
                            className={buttonClassname}
                            classes={{ root: classes.fabRoot }}
                            size="small"
                            aria-label="save"
                            color="primary"
                            onClick={undefined}
                        >
                            {success ? (
                                getCertificateExpired(props.expiration_date) ? (
                                    <ErrorOutlineIcon classes={{ root: classes.fabIconRoot }} />
                                ) : (
                                    <VerifiedUserOutlinedIcon classes={{ root: classes.fabIconRoot }} />
                                )
                            ) : (
                                <HourglassEmptyIcon classes={{ root: classes.fabIconRoot }} />
                            )}
                        </IconButton>
                        {loading && <CircularProgress size={42} className={classes.fabProgress} />}
                    </>
                )}
            </div>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarOpen}
                onClose={handleSnackBarClose}
                autoHideDuration={8000}
                message={
                    success
                        ? getCertificateExpired(props.expiration_date)
                            ? t("certificate_validator.found.verification_expired")
                            : t("certificate_validator.found.verification_success")
                        : loading
                        ? t("certificate_validator.found.verification_processing")
                        : ""
                }
                action={
                    <>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </>
                }
            />
        </div>
    );
}
