import React from "react";
import clsx from "clsx";

// mui
import theme from "../../theme";
import CheckIcon from "@material-ui/icons/Check";

type Props = {
    name: string;
    classes?: string;
    starter?: boolean;
    disabled?: boolean;
};

export default function FeatureItem(props: Props) {
    console.log("props.starter && props.disabled", props.starter && props.disabled);
    return (
        <p
            className={clsx(props.classes, "d-flex align-items-center")}
            style={{
                marginBottom: 5,
                transition: "color 0.2s",
                color: props.starter && props.disabled ? theme.palette.grey[300] : theme.palette.grey[700],
            }}
        >
            <CheckIcon
                className="mr-3"
                style={{
                    color: props.starter
                        ? props.disabled
                            ? "inherit"
                            : theme.palette.grey[600]
                        : theme.palette.vbBlue.main,
                }}
            />
            {props.name}
        </p>
    );
}
