import { ReactElement } from "react";
import { IconButton } from "@material-ui/core";
import OpenInNewIcon from "../../../../Assets/Images/Apps/CertificateValidator/OpenInNewIcon";

// HTML code to include the Print-Button from MUI

interface Props {
    enlargeCertificate: string;
}

export default function EnlargeCertificate(props: Props): ReactElement {
    return (
        <IconButton
            aria-label="enlarge"
            size="medium"
            style={{ position: "absolute", left: 2, bottom: 2 }}
            onClick={() => window.open(props.enlargeCertificate)}
        >
            <OpenInNewIcon />
        </IconButton>
    );
}

/*
* Function to print choosed content like a picture from a page
*
* To use the function include this in youre code
*
import PrintButton form "./PrintHandler";

* In render-return-code:
<EnlargeCertificate enlargeCertificate={xyz} />
*
* xyz is the content (ex.: picture) you want to enlarge
*
*/
