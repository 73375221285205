/* Test component for embedding an iframe to the certificate validator
 * Has the purpose of testing communication between parent window and iframe
 */
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { ROUTES } from "../../utils/routes";

export default function Test() {
    function getContainerHeight(e) {
        var element = window.document.getElementById("certificate-validator-container");
        var validatorContentHeight;
        validatorContentHeight = e.data[1];
        console.log("[TEST.js.getContainerHeight] ", e);

        if (element && validatorContentHeight) {
            element.style.height = validatorContentHeight.toString() + "px";
        }
    }
    useEffect(() => {
        let checkDocumentReadyInterval = setInterval(() => {
            if (document.readyState === "complete") {
                postSearchParamsToIframe();
                clearInterval(checkDocumentReadyInterval);
                // window.addEventListener("resize", getContainerHeight);
                window.addEventListener("message", getContainerHeight);
            }
        }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const postSearchParamsToIframe = () => {
        const frame = document.getElementById("certificate-validator-frame");
        console.log(
            "[Test.postSearchParamsToIframe()] SEND",
            frame ? frame.contentWindow.postMessage(window.location.search || "?") : frame
        );
        // send window object
        // frame.contentWindow.postMessage(JSON.stringify(window._vb_identifier) || "{ }");
        let _vb_identifier = { identifier: "VB-CV1LP1ALL-9999", default_lg: "en" };
        if (frame) {
            frame.contentWindow.postMessage(
                JSON.stringify(_vb_identifier) || '{identifier:"", default_lg:"en"}',
                "http://localhost:3001"
            );
            frame.contentWindow.postMessage(window.location.href, "http://localhost:3001");
        }
    };
    // cer-ba00f608-ab99-4c80-b1e6-a8e39bb3
    if (!window.location.hostname.includes("localhost")) return <Redirect to={ROUTES.notfound} />;
    return (
        <div>
            This is a test page. The iframe will be below{" "}
            {/* <button onClick={() => postSearchParamsToIframe()}>Send</button> */}
            <div
                style={{ width: "100%", height: "480px", border: "1px solid #bb00ee" }}
                id="certificate-validator-container"
            >
                <iframe
                    id="certificate-validator-frame"
                    src="http://localhost:3001/certificate-validator"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    title="Certificate Validator"
                />
            </div>
            {/* <iframe
                id="certificate-validator-frame"
                src="http://localhost:3000/certificate-validator"
                title="W3Schools Free Online Web Tutorials"
                style={{
                    border: "1px solid grey",
                    position: "fixed",
                    top: 20,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    width: "80%",
                    height: "500px",
                    border: "none",
                    margin: 0,
                    padding: 0,
                    overflow: "hidden",
                    zIndex: 99,
                }}
            /> */}
        </div>
    );
}

// CODE TO EMBED:
// Make sure to embed the iframe and also pass the id <certificate-validator-frame>!
/*
<script>
  (function() {
    let checkDocumentReadyInterval = setInterval(() => {
      if (document.readyState === "complete") {
        postSearchParamsToIframe();
        clearInterval(checkDocumentReadyInterval);
      }
    }, 100);
  })();
  const postSearchParamsToIframe = () => {
    let frame = document.getElementById("certificate-validator-frame");
    console.log("frame", frame);
    console.log("window.location.searc", window.location.search);
    frame.contentWindow.postMessage(window.location.search, "https://public.virtualbadge.io");
  };
</script>
*/
