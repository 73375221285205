import theme from "../../../theme";

/**
 * Defines a complex shadow effect used in PageCarousel components.
 *
 * This constant represents a layered shadow style applied to various elements
 * within the PageCarousel, such as indicators and buttons, to create depth
 * and visual hierarchy.
 *
 * @remarks
 * The shadow is composed of three layers, each contributing to the overall effect:
 * 1. A close, subtle shadow
 * 2. A medium-distance shadow
 * 3. A wider, more diffuse shadow
 *
 * These values are directly sourced from the Figma design specifications to
 * ensure consistency between the design and implementation.
 */
export const COMPLEX_SHADOW = `
    0px 5.24px 1.75px -3.49px rgba(0, 0, 0, 0.2),
    0px 3.49px 3.49px 0px rgba(0, 0, 0, 0.14),
    0px 1.75px 8.73px 0px rgba(0, 0, 0, 0.12)
`;

/**
 * Represents the primary blue color from the theme with 40% opacity.
 *
 * This constant uses a template literal to access the main blue color
 * from the theme and appends a hexadecimal opacity value.
 *
 * @remarks
 * The opacity is represented as a two-digit hexadecimal value:
 * - 66 in hexadecimal is equivalent to 102 in decimal
 * - 102 / 255 ≈ 0.4, which represents 40% opacity
 */
export const PRIMARY_BLUE_MAIN_WITH_OPACITY_40 = `${theme.palette.vbBlue.main}66`;

/**
 * Represents the dark blue color from the theme with 40% opacity.
 *
 * This constant uses a template literal to access the dark blue color
 * from the theme and appends a hexadecimal opacity value.
 *
 * @remarks
 * The opacity is represented as a two-digit hexadecimal value:
 * - 66 in hexadecimal is equivalent to 102 in decimal
 * - 102 / 255 ≈ 0.4, which represents 40% opacity
 */
export const PRIMARY_BLUE_DARK_WITH_OPACITY_40 = `${theme.palette.vbBlue.dark}66`;

/**
 * The crucial part of the Carousel logic. It is used to calculate the width of the CarouselItem.
 *
 * @remarks
 * Depending on this value we calculate the initial offset of the track element and set the step of each slide.
 *
 * If the value is 70, the width of the CarouselItem will be 70% of the CarouselContainer.
 * The initial offset of the track element will be 15% (100 - 70 / 2).
 * The step of each slide will be 70%.`
 *
 */
export const ITEM_WIDTH_PERCENTAGE = 70;
