import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getMaximumInObjectArray, getUrlParams } from "../../utils/utils";
import { ROUTES } from "../../utils/routes";

// redux
import { connect } from "react-redux";

// material UI
import { withStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LinearProgress from "@material-ui/core/LinearProgress";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";

// lodash
import cloneDeep from "lodash/cloneDeep";

// Time until alert expires in ms
const DEFAULT_DURATION = 5000;

const ROUTES_TO_HIDE_ALERT = [];

const StyledButton = withStyles({
    root: {
        "&:hover": {
            backgroundColor: "rgba(0,0,0,.3)",
        },
        color: "white",
        backgroundColor: "rgba(0,0,0,.5)",
        marginLeft: "14px",
    },
    label: {
        textTransform: "capitalize",
    },
})(Button);

// The top level alerts entry. Acts as the alerts manager.
class Alerts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertOptions: {
                badgeLoading: false,
                permissions: false,
            },
        };
    }

    static propTypes = {
        // coming from redux
        error: PropTypes.object,
        message: PropTypes.object,
    };

    componentDidUpdate = (prevProps) => {
        // only update options on new message
        if (prevProps.message.timestamp !== this.props.message.timestamp) {
            if (this.props.message.alertOptions && Object.keys(this.props.message.alertOptions).length > 0) {
                this.setState({ alertOptions: { ...this.state.alertOptions, ...this.props.message.alertOptions } });
            } else {
                this.setState({
                    alertOptions: {
                        badgeLoading: false,
                        permissions: false,
                        onboarding: false,
                        customMessage: "",
                    },
                });
            }
        }
    };

    render() {
        const { message: messages } = this.props;
        const urlParams = getUrlParams();
        const customMessage = urlParams.get("message");
        let alerts = [];

        // Messages
        if (messages.msg) {
            alerts.push({
                severity: messages.severity,
                message: messages.msg,
                timestamp: messages.timestamp,
                duration: messages.duration,
            });
        }

        // Only show the latest alert
        let latestIndex = getMaximumInObjectArray(alerts, "timestamp");
        let latestAlert = [];
        if (alerts.length != 0) latestAlert = alerts[latestIndex];
        else return null;

        try {
            if (latestAlert.message instanceof Object) {
                // if message is of structure: {detail: "A server error occurred."}
                latestAlert.message = latestAlert.message["detail"];
            }
        } catch {}

        return (
            <div>
                <CustomAlert
                    severity={latestAlert.severity}
                    message={latestAlert.messageObject || latestAlert.message}
                    duration={latestAlert.duration || DEFAULT_DURATION}
                    timestamp={latestAlert.timestamp}
                    isBadgeLoading={this.props.isBadgeLoading}
                    alertOptions={this.state.alertOptions}
                />
            </div>
        );
    }
}

// Transition for the alert
function SlideUpTransition(props) {
    return <Slide {...props} direction="up" />;
}

// The actual alert component.
// Takes in severity and message as props.
function CustomAlert(props) {
    const [open, setOpen] = React.useState(true);

    useEffect(() => {
        setOpen(true);
    }, [props]);

    return (
        <div key={props.timestamp}>
            <Snackbar
                open={open}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={props.duration}
                onClose={(event, reason) => {
                    if (reason === "clickaway") return; // ignore closing when clicking somewhere on the page
                    setOpen(false);
                }}
            >
                <MuiAlert
                    severity={props.severity}
                    elevation={6}
                    className="align-items-center"
                    variant="filled"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            {!props.isBadgeLoading && <CloseIcon fontSize="inherit" />}
                        </IconButton>
                    }
                >
                    {!props.alertOptions.customMessage && <span>{props.message}</span>}
                    {typeof props.message !== "object" && props.message.includes("Uploading your badge design") && (
                        <LinearProgress style={{ marginTop: 10 }} />
                    )}
                    {props.alertOptions.permissions && (
                        <span>
                            {props.alertOptions.customMessage || props.alertOptions.customMessage === ""
                                ? props.alertOptions.customMessage
                                : " Want an upgrade?"}
                            <Link to={ROUTES.settings.plans}>
                                <StyledButton
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    {/* View Plans */}
                                    Change Plan
                                </StyledButton>
                            </Link>
                        </span>
                    )}
                    {props.alertOptions.onboarding && (
                        <span>
                            {props.alertOptions.customMessage || props.alertOptions.customMessage === ""
                                ? props.alertOptions.customMessage
                                : ""}
                            <a href={"https://www.youtube.com/watch?v=ThYCLOe9SXk&feature=emb_title"} target="_blank">
                                <StyledButton
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    Need help?
                                </StyledButton>
                            </a>
                        </span>
                    )}
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

CustomAlert.propTypes = {
    severity: PropTypes.string.isRequired,
    message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    duration: PropTypes.number.isRequired,
    isBadgeLoading: PropTypes.bool,
    alertOptions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    error: state.errors,
    message: state.messages,
});

export default connect(mapStateToProps, undefined)(Alerts);
