import React from "react";
import { useTranslation } from "react-i18next";
import { CERTIFICATE_ID_LENGTH } from "./utils/config";
import theme from "../../theme";

// redux
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

// mui
import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

type Props = {
    onSubmit: any;
    validatorContentRef: (node: HTMLDivElement) => HTMLDivElement | undefined;
    inputCredentialId: string;
    onChangeCredentialIdInput: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
};

const useStyles = makeStyles({
    textInputRoot: {
        borderColor: theme.palette.vbBlue.main,
    },
    root: {
        "& label.Mui-focused": {
            borderColor: theme.palette.vbBlue.main,
        },
        "& .MuiInput-underline:after": {
            borderColor: theme.palette.vbBlue.main,
        },
        "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
                borderColor: theme.palette.vbBlue.main,
            },
            "&.Mui-focused fieldset": {
                borderColor: theme.palette.vbBlue.main,
            },
        },
    },
});

export default function CertificateNotFound(props: Props) {
    // misc. hooks
    const { t } = useTranslation();
    const classes = useStyles();
    // redux
    const dispatch = useAppDispatch();
    const { certificateError } = useAppSelector((state) => ({
        certificateError: state.certificate_validator.certificateError,
    }));

    const getHeading = () => {
        let heading = "";
        let subheading = "";
        switch (certificateError) {
            case 404:
                heading = t("certificate_validator.not_found.heading.404");
                subheading = t("certificate_validator.not_found.subheading.404");
                break;
            case 410:
                heading = t("certificate_validator.not_found.heading.410");
                subheading = t("certificate_validator.not_found.subheading.410");
                break;
            case 200:
            default:
                heading = t("certificate_validator.not_found.heading.200");
                subheading = t("certificate_validator.not_found.subheading.200");
                break;
        }
        return (
            <>
                <h4 className="mb-3">{heading}</h4>
                <p>{subheading}</p>
            </>
        );
    };

    return (
        <div>
            <div
                key="not-found"
                ref={props.validatorContentRef}
                className="d-flex flex-column align-items-center justify-content-center h-100 mt-3"
            >
                {/* not found */}
                {getHeading()}
                {/* <h4 className="mb-3">{getHeading()}</h4>
                <p>{t("certificate_validator.not_found.subheading")}</p> */}
                <form onSubmit={props.onSubmit}>
                    <div className="cv-not-found-form mt-4">
                        <TextField
                            id="credential-id"
                            variant="outlined"
                            label={t("certificate_validator.not_found.input_label")}
                            size="small"
                            style={{ width: 330 }}
                            inputProps={{
                                maxLength: CERTIFICATE_ID_LENGTH,
                            }}
                            value={props.inputCredentialId}
                            onChange={props.onChangeCredentialIdInput}
                            placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxx"
                            classes={classes}
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            type="submit"
                            style={{ borderColor: theme.palette.vbBlue.main, color: theme.palette.vbBlue.main }}
                        >
                            {t("certificate_validator.not_found.button_label")}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
