import {
    GET_CERTIFICATE_DETAILS,
    CLEAR_CERTIFICATE_DETAILS,
    SET_CERTIFICATE_ERROR,
    SET_PDF_VALIDITY,
} from "../actions/types";

const initialState: ReduxStoreCertificateValidator.IState = {
    certificateDetails: null,
    certificateError: 200,
    pdfValidity: "not_checked",
};

export default function certificateValidator(
    state = initialState,
    action: ReduxStoreCertificateValidator.IAction
): ReduxStoreCertificateValidator.IState {
    switch (action.type) {
        case GET_CERTIFICATE_DETAILS:
            return {
                ...state,
                certificateDetails: action.payload,
                certificateError: 200,
            };
        case CLEAR_CERTIFICATE_DETAILS:
            return {
                ...state,
                certificateDetails: initialState.certificateDetails,
            };
        case SET_CERTIFICATE_ERROR:
            return {
                ...state,
                certificateError: action.payload as ReduxStoreCertificateValidator.TStateCertificateError,
            };
        case SET_PDF_VALIDITY:
            return {
                ...state,
                pdfValidity: action.payload,
            };
        default:
            return state;
    }
}
