import { createMuiTheme } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createPalette" {
    interface SimplePaletteColorOptions {
        [key: string]: React.CSSProperties["color"];
    }
    interface Palette {
        vbYellow: SimplePaletteColorOptions;
        vbBlue: SimplePaletteColorOptions;
        vbButton: SimplePaletteColorOptions;
    }
    interface PaletteOptions {
        vbYellow: PaletteColorOptions;
        vbBlue: PaletteColorOptions;
        vbButton: PaletteColorOptions;
    }
}

const theme = createMuiTheme({
    palette: {
        primary: {
            light: "#488FFF",
            main: "#005CEF",
            dark: "#004FCC",
            darkdark: "#223D71",
            header: "#08213D",
        },
        vbBlue: {
            light: "#325ba8",
            main: "#223D71",
            dark: "#1b305a",
            darkdark: "#2a2f4b",
        },
        vbYellow: {
            light: "#FFE04C",
            main: "#FFD200",
            // darker is 2 percentage points lower in HSL L value
            darker: "#F5CA00",
            dark: "#CCA800",
        },
        vbButton: {
            main: "#9BADB2",
            background: "#FAFCFF",
            border: "#C5D8DE",
            writing: "#9BADB2",
            icon: "#C5D8DE",
        },
    },
});

export default theme;
