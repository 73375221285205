/**
 * Remove price_ prefix from id to pass it to the query param
 *
 * @param price_id
 * @returns
 */
export const slicePriceId = (price_id: string) => price_id.slice("price_".length);

/**
 * Append "price_" prefix to the passed in id for filtering it in the pricing object
 * @param price_id
 * @returns
 */
export const appendPriceIdPrefix = (price_id: string) => `price_${price_id}`;

/**
 * Get the maximum recipient amount of monthly and yearly plans
 * @param pricing
 * @returns
 */
export const getMaximumParticipantsInBillingyCycle = (pricing: VbPricing.PricingObject) => {
    let monthlyProductsCount: number = pricing.bundles.expert.products.monthly.length || 7;
    let yearlyProductsCount: number = pricing.bundles.expert.products.yearly?.length || 7;

    let monthlyMaxParticipantCount =
        pricing.bundles.expert.products.monthly[monthlyProductsCount - 1].participant_count || 0;
    let yearlyMaxParticipantCount = 0;
    if (pricing.bundles.expert.products.yearly)
        yearlyMaxParticipantCount =
            pricing.bundles.expert.products.yearly[yearlyProductsCount - 1].participant_count || 0;

    return { monthly: monthlyMaxParticipantCount, yearly: yearlyMaxParticipantCount };
};
