import React from "react";
import { Helmet as ReactHelmet } from "react-helmet";
import { useBrand } from "../../utils/customHooks";

type Props = {};

export default function Helmet({}: Props) {
    const brandManager = useBrand();

    return (
        <ReactHelmet>
            <title>{brandManager.getPageTitle()}</title>
            <link rel="icon" href={`/Favicon/${brandManager.getHtmlHead().faviconName}`} />
            <link rel="apple-touch-icon" href={`/Favicon/${brandManager.getHtmlHead().faviconName}`} />
            <meta name="description" content={brandManager.getPageTitle()} />
            <meta name="theme-color" content={brandManager.getBrandColor().primary} />
        </ReactHelmet>
    );
}
