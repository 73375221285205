import React from "react";
// Redux
import { Provider } from "react-redux";
import store from "../redux/store";

// components
import NoRouter from "./Common/Routing/NoRouter";
import Alerts from "./Common/Alerts";

interface Props {
    // We use 'any' for props here to avoid type errors when passing components
    // with varying or no props. Strictly defining props would cause issues
    // when different components require different prop types, or none at all.
    // Using 'any' ensures flexibility, allowing the component to handle a wide
    // range of components with different prop structures.
    Component: (props: any) => JSX.Element;
}

function AppNoRouter({ Component, ...props }: Props) {
    return (
        <Provider store={store}>
            <div className="pc-app">
                <Alerts />
                <NoRouter component={Component} />
            </div>
        </Provider>
    );
}

export default AppNoRouter;
