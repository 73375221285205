import { styled, IconButton } from "@material-ui/core";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { FC } from "react";
import {
    COMPLEX_SHADOW,
    PRIMARY_BLUE_DARK_WITH_OPACITY_40,
    PRIMARY_BLUE_MAIN_WITH_OPACITY_40,
} from "./pagesCarousel.constants";
import theme from "../../../theme";

const StyledFiberManualRecordIcon = styled(FiberManualRecord)({
    fontSize: "15px",
});

export const StyledIndicatorIconButton = styled(IconButton)(({ isActive }: { isActive: boolean }) => ({
    cursor: "pointer",
    transition: "200ms",
    padding: 0,
    color: isActive ? theme.palette.vbBlue.main : PRIMARY_BLUE_MAIN_WITH_OPACITY_40,
    boxShadow: COMPLEX_SHADOW,
    "&:hover": {
        color: isActive ? theme.palette.vbBlue.dark : PRIMARY_BLUE_DARK_WITH_OPACITY_40,
    },
}));

const StyledIndicators = styled("div")({
    width: "100%",
    position: "absolute",
    display: "inline-flex",
    justifyContent: "center",
    bottom: "-40px",
    gap: "10px",
});

export interface IndicatorProps {
    length: number;
    active: number;
    onClick: (index: number, isNext?: boolean) => void;
}

const CarouselIndicators: FC<IndicatorProps> = ({ length, active, onClick }) => {
    return (
        <StyledIndicators>
            {Array.from({ length }, (_, i) => (
                <StyledIndicatorIconButton
                    key={i}
                    isActive={i === active}
                    onClick={() => onClick(i)}
                    aria-label={`carousel indicator ${i + 1}`}
                >
                    <StyledFiberManualRecordIcon />
                </StyledIndicatorIconButton>
            ))}
        </StyledIndicators>
    );
};

export default CarouselIndicators;
