import {
    START_LOADING,
    STOP_LOADING,
    START_CERTIFICATE_DETAILS_LOADING,
    STOP_CERTIFICATE_DETAILS_LOADING,
    START_REGISTER_LOADING,
    STOP_REGISTER_LOADING,
} from "../actions/types";

const initialState: ReduxStoreLoading.IState = {
    isLoading: false,
    isCertificateLoading: false,
    isRegisterLoading: false,
};

export default function (state = initialState, action: ReduxStoreLoading.IAction): ReduxStoreLoading.IState {
    switch (action.type) {
        case START_LOADING:
        case STOP_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        case START_CERTIFICATE_DETAILS_LOADING:
        case STOP_CERTIFICATE_DETAILS_LOADING:
            return {
                ...state,
                isCertificateLoading: action.payload,
            };
        case START_REGISTER_LOADING:
        case STOP_REGISTER_LOADING:
            return {
                ...state,
                isRegisterLoading: action.payload,
            };
        default:
            return state;
    }
}
