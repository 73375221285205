import { CREATE_MESSAGE, CLEAR_MESSAGES } from "../actions/types";

const initialState: ReduxStoreMessage.IState = {
    msg: null,
    severity: null,
    timestamp: null,
    duration: null,
};

export default function (state = initialState, action: ReduxStoreMessage.IAction): ReduxStoreMessage.IState {
    switch (action.type) {
        case CREATE_MESSAGE:
            return (state = action.payload);
        case CLEAR_MESSAGES:
            return (state = initialState);
        default:
            return state;
    }
}
