import React from "react";

const OpenInNewIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_3543_1050)">
                <path
                    d="M5 37C4.2 37 3.5 36.7 2.9 36.1C2.3 35.5 2 34.8 2 34V4C2 3.2 2.3 2.5 2.9 1.9C3.5 1.3 4.2 1 5 1H18.95V4H5V34H35V20.05H38V34C38 34.8 37.7 35.5 37.1 36.1C36.5 36.7 35.8 37 35 37H5ZM15.1 26.05L13 23.9L32.9 4H21.95V1H38V17.05H35V6.15L15.1 26.05Z"
                    fill="black"
                />
                <path
                    d="M19.45 1V0.5H18.95H5C4.05872 0.5 3.2339 0.858991 2.54645 1.54645C1.85899 2.2339 1.5 3.05872 1.5 4V34C1.5 34.9413 1.85899 35.7661 2.54645 36.4536C3.2339 37.141 4.05872 37.5 5 37.5H35C35.9413 37.5 36.7661 37.141 37.4536 36.4536C38.141 35.7661 38.5 34.9413 38.5 34V20.05V19.55H38H35H34.5V20.05V33.5H5.5V4.5H18.95H19.45V4V1ZM14.7423 26.3994L15.0958 26.7613L15.4536 26.4036L34.5 7.35711V17.05V17.55H35H38H38.5V17.05V1V0.5H38H21.95H21.45V1V4V4.5H21.95H31.6929L12.6464 23.5464L12.297 23.8959L12.6423 24.2494L14.7423 26.3994Z"
                    stroke="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_3543_1050"
                    x="0"
                    y="0"
                    width="40"
                    height="40"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3543_1050" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3543_1050" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};

export default OpenInNewIcon;
