import { FC } from "react";
import { IconButton, IconButtonProps, styled } from "@material-ui/core";

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import {
    COMPLEX_SHADOW,
    PRIMARY_BLUE_DARK_WITH_OPACITY_40,
    PRIMARY_BLUE_MAIN_WITH_OPACITY_40,
} from "./pagesCarousel.constants";

import theme from "../../../theme";

type TButtonPosition = "left" | "right";

const StyledIconButton = styled(IconButton)(({ buttonPosition }: { buttonPosition: TButtonPosition }) => ({
    position: "absolute",
    width: "32px",
    height: "32px",
    top: "50%",
    transform: "translateY(-50%)",
    color: theme.palette.common.white,
    backgroundColor: PRIMARY_BLUE_MAIN_WITH_OPACITY_40,
    boxShadow: COMPLEX_SHADOW,

    "&:hover": {
        backgroundColor: PRIMARY_BLUE_DARK_WITH_OPACITY_40,
    },
    "&.Mui-disabled": {
        color: theme.palette.common.white,
        backgroundColor: PRIMARY_BLUE_MAIN_WITH_OPACITY_40,
        opacity: 0.2,
    },
    ...(buttonPosition === "left" ? { left: 12 } : { right: 12 }),
}));

interface CarouselButtonProps extends Omit<IconButtonProps, "position"> {
    position: TButtonPosition;
}

const CarouselButton: FC<CarouselButtonProps> = ({ position, ...props }) => (
    <StyledIconButton buttonPosition={position} {...props}>
        {position === "left" ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
    </StyledIconButton>
);

export default CarouselButton;
