import { FC } from "react";

import { COMPLEX_SHADOW } from "./pagesCarousel.constants";
import { Box, styled } from "@material-ui/core";

interface CarouselItemProps {
    src: string;
    alt: string;
    position: "left" | "center" | "right";
    itemWidth: number; // Is used as percentage value in flex property.
}

const CarouselItemWrapper = styled(Box)(({ itemWidth }: { itemWidth: number }) => ({
    flex: `0 0 ${itemWidth}%`,
    maxWidth: "100%",
    boxSizing: "border-box",
    padding: "0 10px",
}));

const CarouselItem: FC<CarouselItemProps> = ({ src, alt, position, itemWidth }) => (
    <CarouselItemWrapper itemWidth={itemWidth}>
        <img
            src={src}
            alt={alt}
            style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                boxShadow: COMPLEX_SHADOW,
                opacity: position === "center" ? 1 : 0.3,
                transition: "opacity 0.5s ease-in-out",
            }}
        />
    </CarouselItemWrapper>
);

export default CarouselItem;
