import { GET_PRICING_OBJECT } from "../actions/types";

const initialState: ReduxStorePricing.IState = {
    pricing: undefined,
};

export default function (state = initialState, action: ReduxStorePricing.IAction): ReduxStorePricing.IState {
    switch (action.type) {
        case GET_PRICING_OBJECT:
            return {
                pricing: action.payload,
            };
        default:
            return state;
    }
}
