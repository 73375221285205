import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./Components/App";
import AppNoRouter from "./Components/AppNoRouter";
import reportWebVitals from "./reportWebVitals";

// i18n
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

// components
import Register from "./Components/Apps/Register/Register";
import PlanSelectionWrapper from "./Components/Apps/Pricing/PlanSelectionWrapper";
import LinkedinCertificateLinkGenerator from "./Components/Apps/LinkedinCertificateLinkGenerator/LinkedinCertificateLinkGenerator";
import UTF8Converter from "./Components/Apps/UTF8Converter/UTF8Converter";
import CertificateValidator from "./Components/Apps/CertificateValidator/CertificateValidator";

let root = document.getElementById("root");
let rootPlans = document.getElementById("root-plans"); // pricing
let rootRegister = document.getElementById("root-register");
let rootUtf8Converter = document.getElementById("root-utf8-converter");
let rootCertificateValidator = document.getElementById("root-certificate-validator");
let rootLinkedinCertificateLinkGenerator = document.getElementById("root-linkedin-certificate-generator");

if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.debug = () => {};
}

// either only root or multiple single in one page
if (root) {
    ReactDOM.render(
        <React.StrictMode>
            <I18nextProvider i18n={i18n}>
                <App />
            </I18nextProvider>
        </React.StrictMode>,
        root
    );
} else {
    if (rootRegister) {
        ReactDOM.render(
            <React.StrictMode>
                <I18nextProvider i18n={i18n}>
                    <AppNoRouter Component={Register} />
                </I18nextProvider>
            </React.StrictMode>,
            rootRegister
        );
    }
    if (rootPlans) {
        ReactDOM.render(
            <React.StrictMode>
                <I18nextProvider i18n={i18n}>
                    <AppNoRouter Component={PlanSelectionWrapper} />
                </I18nextProvider>
            </React.StrictMode>,
            rootPlans
        );
    }
    if (rootLinkedinCertificateLinkGenerator) {
        ReactDOM.render(
            <React.StrictMode>
                <I18nextProvider i18n={i18n}>
                    <AppNoRouter Component={LinkedinCertificateLinkGenerator} />
                </I18nextProvider>
            </React.StrictMode>,
            rootLinkedinCertificateLinkGenerator
        );
    }
    if (rootUtf8Converter) {
        ReactDOM.render(
            <React.StrictMode>
                <I18nextProvider i18n={i18n}>
                    <AppNoRouter Component={UTF8Converter} />
                </I18nextProvider>
            </React.StrictMode>,
            rootUtf8Converter
        );
    }
    if (rootCertificateValidator) {
        ReactDOM.render(
            <React.StrictMode>
                <I18nextProvider i18n={i18n}>
                    {/* @ts-ignore */}
                    <AppNoRouter Component={CertificateValidator} />
                </I18nextProvider>
            </React.StrictMode>,
            rootCertificateValidator
        );
    }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
