export const ALLOWED_HOSTS = [
    "http://127.0.0.1:3000",
    "http://localhost:3000",
    "http://127.0.0.1:3001",
    "http://localhost:3001",
    "https://virtualbadge.io",
    "https://www.virtualbadge.io",
    "https://public.virtualbadge.io",
    "https://dev.public.virtualbadge.io",
    "https://www.schulungszertifikate.de",
    "https://schulungszertifikate.de",
    "https://public.schulungszertifikate.de",
    "https://dev.public.schulungszertifikate.de",
];
