// this file is the root reducer. It acts as a meeting place for all other reducers
// like e.g. an event reducer, a lead reducer

import { combineReducers } from "redux";
import certificate_validator from "./certificate_validator";
import loading from "./loading";
import messages from "./messages";
import pricing from "./pricing";
import register from "./register";

export default combineReducers({
    certificate_validator,
    loading,
    messages,
    pricing,
    register,
});
