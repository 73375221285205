import theme from "../../../theme";
import { makeStyles } from "@material-ui/core/styles";
import tinycolor from "tinycolor2";

export const infoText = {
    color: theme.palette.grey[600],
    fontSize: 14,
    lineHeight: "16px",
    marginBottom: 4,
};

export const customInputFieldCommonStyles = {
    border: "none",
    height: 20,
    backgroundColor: "rgba(255, 255, 255, 0)",
    outline: "none",
    width: "100%",
    fontSize: 14,
};

// if desktop mode, the margin to the middle of the checkout form
export const marginToMiddle = 64;

// styles for the stripe elements
export const cardNumberElementOptions = {
    placeholder: "1234 1234 1234 1234",
    showIcon: true,
};
export const cardExpiryElementOptions = {
    placeholder: "MM / YY",
};
export const cardCvcElementOptions = {
    placeholder: "CVC",
};

// checkout button
const ThemePrimaryMainLighter = tinycolor(theme.palette.vbBlue.main).lighten(10).toString();
export const useCheckoutBtnStyles = makeStyles({
    checkoutButtonRoot: {
        animationName: "waves-animation",
        animationDuration: "2.6s",
        animationIterationCount: "infinite",
        backgroundSize: "600px",
        backgroundImage: `linear-gradient(90deg, ${theme.palette.vbBlue.main} 0px, ${ThemePrimaryMainLighter} 120px, ${theme.palette.vbBlue.main} 240px)`,
        color: "#ffffff",
    },
});

export const skeletonPlanSelection = {
    maxWidth: 388,
    width: "100%",
};

// the negative margin when on a desktop with transparent static header
export const negativeMarginTopDesktop = -48;

export const usePlanSelectionStyles = makeStyles((theme) => ({
    margin: {
        height: theme.spacing(4),
    },
    blankBackground: {
        backgroundColor: "transparent",
        marginBottom: 32,
    },
    mb2: {
        marginBottom: "2rem",
    },
    textCenter: {
        textAlign: "center",
    },
    toggleGroup: {
        borderRadius: 10,
        backgroundColor: "white",
    },
}));

// PRICING CARD
export const usePricingCardStyles = makeStyles({
    margin: {
        height: theme.spacing(3),
    },
    pricingCard: {
        position: "relative",
        border: "1px solid #DBDBDB",
        boxSizing: "border-box",
        backgroundColor: "white",
        boxShadow: "none",
        borderRadius: 8,
        padding: "24px 32px",
        color: "#373232",
        width: "32%",
        transition: "background-color 0.3s, color 0.2s",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        maxWidth: 388,
    },
    pricingCardDisabled: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[400],
    },
    thick: {
        fontSize: "2rem",
        fontWeight: 600,
    },
    bitThick: {
        fontSize: "1.1rem",
        fontWeight: 500,
    },
    thin: {
        fontSize: "1rem",
        fontWeight: 400,
    },
    superThick: {
        fontSize: "2.7rem",
        marginBottom: 0,
        fontWeight: 800,
        [theme.breakpoints.down("xs")]: {
            marginRight: 10,
        },
    },
    semiThick: {
        fontSize: "1.8rem",
        marginBottom: 0,
        fontWeight: 400,
        color: theme.palette.grey[500],
        [theme.breakpoints.down("xs")]: {
            marginRight: 10,
        },
    },
    lessMarginBottom: {
        marginBottom: -3,
    },
    smallerText: {
        fontSize: 15,
    },
    bitMarginBottom: {
        marginBottom: 5,
    },
    horizonatlDivider: {
        backgroundColor: "#CDCDCD",
        width: "100%",
        height: 1,
        marginBottom: 20,
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    ctaButton: {
        borderRadius: 4,
        padding: "5px 20px",
        borderColor: theme.palette.vbBlue.main,
    },
    ctaButtonDisabled: {
        backgroundColor: "#8A94A5",
    },
    select: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 120,
        border: "solid 1px #CDCDCD",
        borderRadius: 5,
        padding: 5,
        marginTop: -30,
        marginRight: -30,
        [theme.breakpoints.down("xs")]: {
            marginBottom: -10,
            marginTop: -40,
            marginRight: -40,
            padding: 2,
        },
    },
});
