interface ITaxIdByCountry {
    // tax id identifier accoring to stripe: https://stripe.com/docs/api/customer_tax_ids/object?lang=python
    taxIdType: string;
    // displayable tax id identifier
    taxIdShort: string;
    country: string;
    // placeholder for the input field
    placeholder: string;
    // the ISO country code: https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes. Needed for reference to ReduxAccountSettings.ICountry
    countryCode: string;
}

const supportedByStripeOthers: ITaxIdByCountry[] = [
    {
        taxIdType: "ae_trn",
        taxIdShort: "AE TRN",
        country: "United Arab Emirates",
        placeholder: "123456789012345",
        countryCode: "AE",
    },
    {
        taxIdType: "au_abn",
        taxIdShort: "AU ABN",
        country: "Australia",
        placeholder: "12345678912",
        countryCode: "AU",
    },
    {
        taxIdType: "br_cnpj",
        taxIdShort: "BR CNPJ",
        country: "Brazil",
        placeholder: "01.234.456/5432-10",
        countryCode: "BR",
    },
    {
        taxIdType: "br_cpf",
        taxIdShort: "BR CPF",
        country: "Brazil",
        placeholder: "123.456.789-87",
        countryCode: "BR",
    },
    {
        taxIdType: "ca_bn",
        taxIdShort: "CA BN",
        country: "Canada",
        placeholder: "123456789",
        countryCode: "CA",
    },
    {
        taxIdType: "ca_gst_hst",
        taxIdShort: "CA GST/HST",
        country: "Canada",
        placeholder: "123456789RT0002",
        countryCode: "CA",
    },
    {
        taxIdType: "ca_pst_bc",
        taxIdShort: "CA PST-BC",
        country: "Canada",
        placeholder: "PST-1234-5678",
        countryCode: "CA",
    },
    {
        taxIdType: "ca_pst_mb",
        taxIdShort: "CA PST-MB",
        country: "Canada",
        placeholder: "123456-7",
        countryCode: "CA",
    },
    {
        taxIdType: "ca_pst_sk",
        taxIdShort: "CA PST-SK",
        country: "Canada",
        placeholder: "1234567",
        countryCode: "CA",
    },
    {
        taxIdType: "ca_qst",
        taxIdShort: "CA QST",
        country: "Canada",
        placeholder: "1234567890TQ1234",
        countryCode: "CA",
    },
    {
        taxIdType: "ch_vat",
        taxIdShort: "CH VAT",
        country: "Switzerland",
        placeholder: "CHE-123.456.789 MWST",
        countryCode: "CH",
    },
    {
        taxIdType: "cl_tin",
        taxIdShort: "CL TIN",
        country: "Chile",
        placeholder: "12.345.678-K",
        countryCode: "CL",
    },
    {
        taxIdType: "es_cif",
        taxIdShort: "ES CIF",
        country: "Spain",
        placeholder: "A12345678",
        countryCode: "ES",
    },
    {
        taxIdType: "hk_br",
        taxIdShort: "HK BR",
        country: "Hong Kong SAR China",
        placeholder: "12345678",
        countryCode: "HK",
    },
    {
        taxIdType: "gb_vat",
        taxIdShort: "GB VAT",
        country: "United Kingdom",
        placeholder: "GB123456789",
        countryCode: "GB",
    },
    {
        taxIdType: "id_npwp",
        taxIdShort: "ID NPWP",
        country: "Indonesia",
        placeholder: "12.345.678.9-012.345",
        countryCode: "ID",
    },
    {
        taxIdType: "il_vat",
        taxIdShort: "IL VAT",
        country: "Israel",
        placeholder: "000012345",
        countryCode: "IL",
    },
    {
        taxIdType: "in_gst",
        taxIdShort: "IN GSt",
        country: "India",
        placeholder: "12ABCDE3456FGZH",
        countryCode: "IN",
    },
    {
        taxIdType: "jp_cn",
        taxIdShort: "JP CN",
        country: "Japan",
        placeholder: "1234567891234",
        countryCode: "JP",
    },
    {
        taxIdType: "jp_rn",
        taxIdShort: "JP RN",
        country: "Japan",
        placeholder: "12345",
        countryCode: "JP",
    },
    {
        taxIdType: "kr_brn",
        taxIdShort: "KR BRN",
        country: "South Korea",
        placeholder: "123-45-67890",
        countryCode: "KR",
    },
    {
        taxIdType: "li_uid",
        taxIdShort: "LI UID",
        country: "Liechtenstein",
        placeholder: "CHE123456789",
        countryCode: "LI",
    },
    {
        taxIdType: "mx_rfc",
        taxIdShort: "MX RFC",
        country: "Mexico",
        placeholder: "ABC010203AB9",
        countryCode: "MX",
    },
    {
        taxIdType: "my_frp",
        taxIdShort: "MY FRP",
        country: "Malaysia",
        placeholder: "12345678",
        countryCode: "MY",
    },
    {
        taxIdType: "my_itn",
        taxIdShort: "MY ITN",
        country: "Malaysia",
        placeholder: "C 1234567890",
        countryCode: "MY",
    },
    {
        taxIdType: "my_sst",
        taxIdShort: "MY SST",
        country: "Malaysia",
        placeholder: "A12-3456-78912345",
        countryCode: "MY",
    },
    {
        taxIdType: "no_vat",
        taxIdShort: "NO VAT",
        country: "Norway",
        placeholder: "123456789MVA",
        countryCode: "NO",
    },
    {
        taxIdType: "nz_gst",
        taxIdShort: "NZ GST",
        country: "New zealand",
        placeholder: "123456789",
        countryCode: "NZ",
    },
    {
        taxIdType: "ru_inn",
        taxIdShort: "RU INN",
        country: "Russia",
        placeholder: "1234567891",
        countryCode: "RU",
    },
    {
        taxIdType: "ru_kpp",
        taxIdShort: "RU KPP",
        country: "Russia",
        placeholder: "123456789",
        countryCode: "RU",
    },
    {
        taxIdType: "sa_vat",
        taxIdShort: "SA VAT",
        country: "Saudi Arabia",
        placeholder: "123456789012345",
        countryCode: "SA",
    },
    {
        taxIdType: "sg_gst",
        taxIdShort: "SG GST",
        country: "Singapore",
        placeholder: "M12345678X",
        countryCode: "SG",
    },
    {
        taxIdType: "sg_uen",
        taxIdShort: "SG UEN",
        country: "Singapore",
        placeholder: "123456789F",
        countryCode: "SG",
    },
    {
        taxIdType: "th_vat",
        taxIdShort: "TH VAT",
        country: "Thailand",
        placeholder: "1234567891234",
        countryCode: "TH",
    },
    {
        taxIdType: "tw_vat",
        taxIdShort: "TW VAT",
        country: "Taiwan",
        placeholder: "12345678",
        countryCode: "TW",
    },
    {
        taxIdType: "us_ein",
        taxIdShort: "US EIN",
        country: "United States",
        placeholder: "12-3456789",
        countryCode: "US",
    },
    {
        taxIdType: "za_vat",
        taxIdShort: "ZA VAT",
        country: "South Africa",
        placeholder: "4123456789",
        countryCode: "ZA",
    },
];

/** tax id codes
 *  X ae_trn, au_abn, br_cnpj, br_cpf, ca_bn, ca_gst_hst, ca_pst_bc, ca_pst_mb, ca_pst_sk, ca_qst, ch_vat, cl_tin, es_cif
 *  eu_vat, gb_vat, hk_br, id_npwp, il_vat, in_gst, jp_cn, jp_rn, kr_brn, li_uid, mx_rfc, my_frp, my_itn, my_sst, no_vat, nz_gst, ru_inn, ru_kpp, sa_vat, sg_gst, sg_uen, th_vat, tw_vat, us_ein, or za_vat
 */

export const supportedByStripeEuVat: ITaxIdByCountry[] = [
    {
        taxIdType: "at_vat",
        taxIdShort: "AT VAT",
        country: "Austria",
        placeholder: "ATU12345678",
        countryCode: "AT",
    },
    {
        taxIdType: "be_vat",
        taxIdShort: "BE VAT",
        country: "Belgium",
        placeholder: "BE0123456789",
        countryCode: "BE",
    },
    {
        taxIdType: "bg_vat",
        taxIdShort: "BG VAT",
        country: "Bulgaria",
        placeholder: "BG0123456789",
        countryCode: "BG",
    },
    {
        taxIdType: "cy_vat",
        taxIdShort: "CY VAT",
        country: "Cyprus",
        placeholder: "CY12345678Z",
        countryCode: "CY",
    },
    {
        taxIdType: "cz_vat",
        taxIdShort: "CZ VAT",
        country: "Czech Republic",
        placeholder: "CZ1234567890",
        countryCode: "CZ",
    },
    {
        taxIdType: "de_vat",
        taxIdShort: "DE VAT",
        country: "Germany",
        placeholder: "DE123456789",
        countryCode: "DE",
    },
    {
        taxIdType: "dk_vat",
        taxIdShort: "DK VAT",
        country: "Denmark",
        placeholder: "DK12345678",
        countryCode: "DK",
    },
    {
        taxIdType: "ee_vat",
        taxIdShort: "EE VAT",
        country: "Estonia",
        placeholder: "EE123456789",
        countryCode: "FE",
    },
    {
        taxIdType: "es_vat",
        taxIdShort: "ES VAT",
        country: "Spain",
        placeholder: "ESA1234567Z",
        countryCode: "ES",
    },
    {
        taxIdType: "fi_vat",
        taxIdShort: "FI VAT",
        country: "Finland",
        placeholder: "FI12345678",
        countryCode: "FI",
    },
    {
        taxIdType: "fr_vat",
        taxIdShort: "FR VAT",
        country: "France",
        placeholder: "FRAB123456789",
        countryCode: "FR",
    },
    {
        taxIdType: "gr_vat",
        taxIdShort: "GR VAT",
        country: "Greece",
        placeholder: "EL123456789",
        countryCode: "GR",
    },
    {
        taxIdType: "hr_vat",
        taxIdShort: "HR VAT",
        country: "Croatia",
        placeholder: "HR12345678912",
        countryCode: "HR",
    },
    {
        taxIdType: "hu_vat",
        taxIdShort: "HU VAT",
        country: "Hungary",
        placeholder: "HU12345678912",
        countryCode: "HU",
    },
    {
        taxIdType: "ie_vat",
        taxIdShort: "IE VAT",
        country: "Ireland",
        placeholder: "IE1234567AB",
        countryCode: "IE",
    },
    {
        taxIdType: "it_vat",
        taxIdShort: "IT VAT",
        country: "Italy",
        placeholder: "IT12345678912",
        countryCode: "IT",
    },
    {
        taxIdType: "lt_vat",
        taxIdShort: "LT VAT",
        country: "Lithuania",
        placeholder: "LT123456789123",
        countryCode: "LT",
    },
    {
        taxIdType: "lu_vat",
        taxIdShort: "LU VAT",
        country: "Luxembourg",
        placeholder: "LU12345678",
        countryCode: "LU",
    },
    {
        taxIdType: "lv_vat",
        taxIdShort: "LV VAT",
        country: "Latvia",
        placeholder: "LV12345678912",
        countryCode: "LV",
    },
    {
        taxIdType: "mt_vat",
        taxIdShort: "Mt VAT",
        country: "Malta",
        placeholder: "MT12345678",
        countryCode: "MT",
    },
    {
        taxIdType: "nl_vat",
        taxIdShort: "NL VAT",
        country: "Netherlands",
        placeholder: "NL123456789B12",
        countryCode: "NL",
    },
    {
        taxIdType: "pl_vat",
        taxIdShort: "PL VAT",
        country: "Poland",
        placeholder: "PL1234567890",
        countryCode: "PL",
    },
    {
        taxIdType: "pt_vat",
        taxIdShort: "PT VAT",
        country: "Portugal",
        placeholder: "PT123456789",
        countryCode: "PT",
    },
    {
        taxIdType: "ro_vat",
        taxIdShort: "RO VAT",
        country: "Romania",
        placeholder: "RO1234567891",
        countryCode: "RO",
    },
    {
        taxIdType: "se_vat",
        taxIdShort: "SE VAT",
        country: "Sweden",
        placeholder: "SE123456789123",
        countryCode: "SE",
    },
    {
        taxIdType: "si_vat",
        taxIdShort: "SI VAT",
        country: "Slovenia",
        placeholder: "SI12345678",
        countryCode: "SI",
    },
    {
        taxIdType: "sk_vat",
        taxIdShort: "SK VAT",
        country: "Slovakia",
        placeholder: "SK1234567891",
        countryCode: "SK",
    },
    {
        taxIdType: "xi_vat",
        taxIdShort: "XI VAT",
        country: "United Kingdom",
        placeholder: "XI123456789",
        countryCode: "GB",
    },
];

// Sort by taxIdType
const taxIdsByCountry = [...supportedByStripeOthers, ...supportedByStripeEuVat].sort((a, b) =>
    a.taxIdShort > b.taxIdShort ? 1 : b.taxIdShort > a.taxIdShort ? -1 : 0
);

// the extracted country codes as list
const supportedCountryCodes: string[] = taxIdsByCountry.map((taxIdByCountry) => taxIdByCountry.countryCode);

export { taxIdsByCountry, supportedCountryCodes };
