const breakpoints: IWindowBreakpoints<ICertificateValidatorStyles> = {
    sizeBig: {
        bigFont: 20,
        smallFont: 16,
        certifPicToInfo: "row",
        issueAndExpireToDate: "column",
        certifPicHeight: {
            A4_LANDSCAPE: 360,
            A4_PORTRAIT: 486,
            US_LETTER_LANDSCAPE: 360,
            US_LETTER_PORTRAIT: 486,
            BADGE_1_TO_1: 357,
            LEGACY_BADGE_3_TO_4: 486,
        },
        certifPicDivWidth: {
            A4_LANDSCAPE: 486,
            A4_PORTRAIT: 360,
            US_LETTER_LANDSCAPE: 486,
            US_LETTER_PORTRAIT: 360,
            BADGE_1_TO_1: 357,
            LEGACY_BADGE_3_TO_4: 360,
        },
        maxContentWidth: 1550,
        infoToPicMargin: 0,
        muiGridUseing: 10,
        useTotalWidth: false,
        contentHeight: 860,
        shareButtonColumnCount: 10,
    },
    sizeMedium: {
        bigFont: 20,
        smallFont: 16,
        certifPicToInfo: "row",
        issueAndExpireToDate: "column",
        certifPicHeight: {
            A4_LANDSCAPE: 360,
            A4_PORTRAIT: 486,
            US_LETTER_LANDSCAPE: 360,
            US_LETTER_PORTRAIT: 486,
            BADGE_1_TO_1: 357,
            LEGACY_BADGE_3_TO_4: 486,
        },
        certifPicDivWidth: {
            A4_LANDSCAPE: 486,
            A4_PORTRAIT: 360,
            US_LETTER_LANDSCAPE: 486,
            US_LETTER_PORTRAIT: 360,
            BADGE_1_TO_1: 357,
            LEGACY_BADGE_3_TO_4: 360,
        },
        maxContentWidth: 1250,
        infoToPicMargin: 0,
        muiGridUseing: 10,
        useTotalWidth: false,
        contentHeight: 860,
        shareButtonColumnCount: 8,
    },
    sizeSmall: {
        bigFont: 18,
        smallFont: 14,
        certifPicToInfo: "row",
        issueAndExpireToDate: "column",
        certifPicHeight: {
            A4_LANDSCAPE: 295,
            A4_PORTRAIT: 400,
            US_LETTER_LANDSCAPE: 295,
            US_LETTER_PORTRAIT: 400,
            BADGE_1_TO_1: 357,
            LEGACY_BADGE_3_TO_4: 400,
        },
        certifPicDivWidth: {
            A4_LANDSCAPE: 420,
            A4_PORTRAIT: 315,
            US_LETTER_LANDSCAPE: 420,
            US_LETTER_PORTRAIT: 315,
            BADGE_1_TO_1: 357,
            LEGACY_BADGE_3_TO_4: 315,
        },
        maxContentWidth: 800,
        infoToPicMargin: 0,
        muiGridUseing: 12,
        useTotalWidth: true,
        contentHeight: 860,
        shareButtonColumnCount: 4,
    },
    smartphone: {
        bigFont: 18,
        smallFont: 14,
        certifPicToInfo: "column",
        issueAndExpireToDate: "column",
        certifPicHeight: {
            A4_LANDSCAPE: 315,
            A4_PORTRAIT: 420,
            US_LETTER_LANDSCAPE: 315,
            US_LETTER_PORTRAIT: 420,
            BADGE_1_TO_1: 357,
            LEGACY_BADGE_3_TO_4: 420,
        },
        certifPicDivWidth: {
            A4_LANDSCAPE: 420,
            A4_PORTRAIT: 315,
            US_LETTER_LANDSCAPE: 420,
            US_LETTER_PORTRAIT: 315,
            BADGE_1_TO_1: 357,
            LEGACY_BADGE_3_TO_4: 315,
        },
        maxContentWidth: "100%",
        infoToPicMargin: 10,
        muiGridUseing: 12,
        useTotalWidth: false,
        contentHeight: 1290,
        shareButtonColumnCount: 4,
    },
    smartphoneLowRes: {
        bigFont: 16,
        smallFont: 12,
        certifPicToInfo: "column",
        issueAndExpireToDate: "column",
        certifPicHeight: {
            A4_LANDSCAPE: 225,
            A4_PORTRAIT: 300,
            US_LETTER_LANDSCAPE: 225,
            US_LETTER_PORTRAIT: 300,
            BADGE_1_TO_1: 255,
            LEGACY_BADGE_3_TO_4: 300,
        },
        certifPicDivWidth: {
            A4_LANDSCAPE: 300,
            A4_PORTRAIT: 225,
            US_LETTER_LANDSCAPE: 300,
            US_LETTER_PORTRAIT: 225,
            BADGE_1_TO_1: 255,
            LEGACY_BADGE_3_TO_4: 225,
        },
        maxContentWidth: "100%",
        infoToPicMargin: 8,
        muiGridUseing: 12,
        useTotalWidth: false,
        contentHeight: 1290,
        shareButtonColumnCount: 4,
    },
};

/**
 * Size handler for the Certificate Validator
 * Change frame-size, sorting, content when the size of the window (or device) is changing
 * Brakepoint are: 1550/1400/800/450
 * Preview-Size and placing of Enlarge-Icon based on format
 * @param {number} screenSize windowWidth
 * @returns style-value
 */
const certificationValidatorSizeHandle = (screenSize: number) => {
    if (screenSize < 450) {
        return breakpoints["smartphoneLowRes"];
    } else if (screenSize < 800) {
        return breakpoints["smartphone"];
    } else if (screenSize < 1350) {
        return breakpoints["sizeSmall"];
    } else if (screenSize < 1550) {
        return breakpoints["sizeMedium"];
    } else {
        return breakpoints["sizeBig"];
    }
};

export default certificationValidatorSizeHandle;
