import { useCallback, useEffect, useRef, useState } from "react";
import { ITEM_WIDTH_PERCENTAGE } from "./pagesCarousel.constants";

const usePagesCarousel = (pages: string[]) => {
    /**
     * State object for the carousel.
     * @property {number} active - The index of the currently active page.
     * @property {number} prevActive - The index of the previously active page.
     * @property {boolean} next - Indicates if the next slide is available.
     */
    const [state, setState] = useState({
        active: 0,
        prevActive: 0,
        next: true,
    });
    const trackRef = useRef<HTMLDivElement>(null);
    const dragStartRef = useRef(0);
    const dragOffsetRef = useRef(0);
    const isDraggingRef = useRef(false);
    const animationRef = useRef(0);

    /**
     * Sets the position of the carousel based on the active index.
     * @param index The index to set the position to.
     */
    const setPositionByIndex = useCallback((index: number) => {
        if (!trackRef.current) return;
        const containerWidth = trackRef.current.offsetWidth;
        const slideWidth = containerWidth * (ITEM_WIDTH_PERCENTAGE / 100);
        const offset = (containerWidth - slideWidth) / 2;
        dragOffsetRef.current = -(slideWidth * index) + offset;
    }, []);

    /**
     * Animates the carousel movement during dragging.
     */
    const animation = useCallback(() => {
        if (!trackRef.current) return;
        trackRef.current.style.transform = `translateX(${dragOffsetRef.current}px)`;
        if (isDraggingRef.current) requestAnimationFrame(animation);
    }, []);

    /**
     * Updates the carousel state to show the next slide.
     * @param index The index of the next slide.
     * @param isNext Optional parameter to indicate if there's a next slide available.
     */
    const setNextSlide = useCallback(
        (index: number, isNext?: boolean) => {
            const nextValue = isNext !== undefined ? isNext : index < pages.length - 1;
            setState((prevState) => ({
                active: index,
                prevActive: prevState.active,
                next: nextValue,
            }));
            setPositionByIndex(index);
        },
        [pages.length, setPositionByIndex]
    );

    /**
     * Handles the click event for the 'next' button.
     */
    const onClickNext = useCallback(
        <T extends Element>(e: React.MouseEvent<T>) => {
            if (e) e.stopPropagation();
            const last = pages.length - 1 < 0 ? 0 : pages.length - 1;
            const nextActive = state.active + 1 > last ? state.active : state.active + 1;
            setNextSlide(nextActive, nextActive < pages.length - 1);
        },
        [state.active, pages.length, setNextSlide]
    );

    /**
     * Handles the click event for the 'previous' button.
     */
    const onClickPrev = useCallback(
        <T extends Element>(e: React.MouseEvent<T>) => {
            if (e) e.stopPropagation();
            const nextActive = state.active - 1 < 0 ? state.active : state.active - 1;
            setNextSlide(nextActive, nextActive < pages.length - 1);
        },
        [state.active, setNextSlide, pages.length]
    );

    /**
     * Initializes the drag operation when the user starts dragging.
     */
    const startDrag = useCallback(
        (event: React.MouseEvent | React.TouchEvent) => {
            event.preventDefault();
            if (!trackRef.current) return;
            const startX = "touches" in event ? event.touches[0].clientX : event.clientX;
            dragStartRef.current = startX - dragOffsetRef.current;
            isDraggingRef.current = true;
            animationRef.current = requestAnimationFrame(animation);
        },
        [animation]
    );

    /**
     * Updates the drag offset as the user continues dragging.
     */
    const drag = useCallback((event: React.MouseEvent | React.TouchEvent) => {
        if (!isDraggingRef.current) return;
        const currentPosition = "touches" in event ? event.touches[0].clientX : event.clientX;
        const currentDrag = currentPosition - dragStartRef.current;
        dragOffsetRef.current = currentDrag;
    }, []);

    /**
     * Finalizes the drag operation and decides whether to change the slide.
     */
    const endDrag = useCallback(() => {
        isDraggingRef.current = false;
        cancelAnimationFrame(animationRef.current);

        if (!trackRef.current) return;
        const containerWidth = trackRef.current.offsetWidth;
        const slideWidth = containerWidth * (ITEM_WIDTH_PERCENTAGE / 100);
        const movedBy = dragOffsetRef.current - (-(slideWidth * state.active) + (containerWidth - slideWidth) / 2);

        if (Math.abs(movedBy) > slideWidth / 4) {
            if (movedBy > 0 && state.active > 0) {
                setNextSlide(state.active - 1);
            } else if (movedBy < 0 && state.active < pages.length - 1) {
                setNextSlide(state.active + 1);
            } else {
                setNextSlide(state.active);
            }
        } else {
            setNextSlide(state.active);
        }
    }, [state.active, pages.length, setNextSlide]);

    // Effect to update position when active slide changes.
    useEffect(() => {
        setPositionByIndex(state.active);
    }, [state.active, setPositionByIndex]);

    // Effect to set initial position and update on window resize.
    useEffect(() => {
        if (trackRef.current) {
            let offset = (100 - ITEM_WIDTH_PERCENTAGE) / 2;
            if (state.active !== 0) {
                offset -= ITEM_WIDTH_PERCENTAGE * state.active;
            }
            trackRef.current.style.transform = `translateX(${offset}%)`;
        }
    }, [state.active]);

    // Effect to handle mouseup and touchend events.
    useEffect(() => {
        const handleMouseUp = () => {
            if (isDraggingRef.current) {
                endDrag();
            }
        };
        document.addEventListener("mouseup", handleMouseUp);
        document.addEventListener("touchend", handleMouseUp);

        return () => {
            document.removeEventListener("mouseup", handleMouseUp);
            document.removeEventListener("touchend", handleMouseUp);
        };
    }, [endDrag]);

    return {
        onClickNext,
        onClickPrev,
        startDrag,
        drag,
        endDrag,
        state,
        setNextSlide,
        trackRef,
        isDraggingRef,
    };
};

export default usePagesCarousel;
