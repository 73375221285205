import { GET_CERTIFICATE_DETAILS, CLEAR_CERTIFICATE_DETAILS, SET_CERTIFICATE_ERROR, SET_PDF_VALIDITY } from "./types";
import axios from "axios";
import { startCertificateLoading, stopCertificateLoading } from "./loading";
import { getBackendUrl } from "../utils/routes";

const DEBUG = false;
const EXAMPLE_CERT = {
    certificate_id: "cert-123",
    certified_person: "Some1 Spezial",
    event_name: "hello there",
    is_demo_event: false,
    organisation_name: "Organisation",
    certificate_description: "MMM MMM MMM MMM MMM MMM MMM MMM MMM MMM MMM MMM MMM MMM MMM MMM MMM \n M ",
    issue_date: "2021-12-13T00:00:00.000000Z",
    expiration_date: "2022-03-13T15:53:39.006852Z",
    course_url: "https://www.master_of_certification.com",
    badge_url:
        "https://vbeventstorage.blob.core.windows.net/event-280/certificate-cer-0d855c13-e79b-443e-be4f-4b346897.png",
};
export const getCertificateDetails =
    (
        certificate_id: string,
        api_identifier: string,
        env: TEnv = "prod",
        brandName: TAccountType,
        callback?: () => void
    ) =>
    (dispatch: any) => {
        if (DEBUG) {
            dispatch({
                type: GET_CERTIFICATE_DETAILS,
                payload: EXAMPLE_CERT,
            });
            return;
        }

        dispatch(startCertificateLoading());

        // get backend
        const API_URL = getBackendUrl(env, brandName);
        console.log(
            "[actions.certificate_validator.getCertificateDetails()] API_URL, certificate_id, api_identifier, env:",
            API_URL,
            certificate_id,
            api_identifier,
            env
        );

        axios
            .get(
                `${API_URL}/api/public/certificate_validation?certificate_id=${certificate_id}&identifier=${api_identifier}&rat=${brandName}`
            )
            .then((res) => {
                dispatch({
                    type: GET_CERTIFICATE_DETAILS,
                    payload: res.data,
                });
                console.log("~/virtualbadge/vb-public-content/src/actions", res.data);
            })
            .catch((err) => {
                console.log("err", err);
                if (axios.isAxiosError(err)) {
                    if (err.response?.status === 404) {
                        // not found
                        dispatch({
                            type: SET_CERTIFICATE_ERROR,
                            payload: 404,
                        });
                    } else if (err.response?.status === 410) {
                        // was deleted
                        dispatch({
                            type: SET_CERTIFICATE_ERROR,
                            payload: 410,
                        });
                    }
                }
                dispatch({
                    type: CLEAR_CERTIFICATE_DETAILS,
                });
            })
            .finally(() => {
                dispatch(stopCertificateLoading());
                setTimeout(() => {
                    if (callback) callback();
                }, 200);
            });
    };

// CLEAR MESSAGES
export const clearCertificateDetails = () => {
    return {
        type: CLEAR_CERTIFICATE_DETAILS,
        payload: null,
    };
};

export const setPdfValidity = (validity: TPDFValidity) => {
    return {
        type: SET_PDF_VALIDITY,
        payload: validity,
    };
};
