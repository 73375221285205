import React, { ReactElement, useState, useEffect } from "react";
import clsx from "clsx";
import { determineParticipantCountToDisplayForPlan, getRoundedMonthlyPrice } from "./utils/library";
import { slicePriceId } from "../../Common/PlanSelection/utils";

// redux
import { useDispatch } from "react-redux";
import { createMessage } from "../../../actions/messages";

// MUI
import theme from "../../theme";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import { useCheckoutBtnStyles, usePricingCardStyles } from "./utils/styles";
import FeatureItem from "./FeatureItem";

// Localization
import { useTranslation } from 'react-i18next';

interface Props {
    pricing: VbPricing.PricingSingleBundle;
    numSelectedParticipants: number;
    selectedCurrency: VbPricing.currencyType;
    billingCycle: VbPricing.billingCycle;
    selectedSliderValue: number;
    // disable the card and the button
    disabled?: boolean;
    // if a custom plan is selected
    isCustom: boolean;
    // If the plan is featured
    featured?: boolean;
    // optional classnames
    className?: string;
}

const PricingCard = (props: Props): ReactElement => {
    // redux
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const classes = usePricingCardStyles(theme);
    const externalClasses = useCheckoutBtnStyles();
    const { pricing, numSelectedParticipants, selectedCurrency, billingCycle } = props;

    const [amount, setAmount] = useState<number>(0);
    const [monthlyAmount, setMonthlyAmount] = useState<number>(0); // used to display the stroke through monthly amount
    const [currentPriceId, setCurrentPriceId] = useState("");

    /**
     * The state variable <participantCountToDisplay> is needed as for some plans the selected
     *  number of participants may not be available. E.g. expert plan starts with 150 participants.
     * So even if 50 are selected, we want to display the 150
     */
    const [participantCountToDisplay, setParticipantCountToDisplay] = useState<number | string>(0);

    /**
     * Update the state variable participantCountToDisplay. Needed, because for some plans, more participants are
     * included in the package than you selected
     */
    useEffect(() => {
        determineParticipantCountToDisplayForPlan(
            pricing.products[props.billingCycle],
            numSelectedParticipants,
            selectedCurrency,
            setParticipantCountToDisplay
        );
    }, [numSelectedParticipants]);

    /**
     * Set the amount (price) of the plan
     */
    useEffect(() => {
        setAmount(getRoundedMonthlyPrice(pricing, numSelectedParticipants, selectedCurrency, props.billingCycle));
        // cannot take the numSelectedParticipants for the monthly amount because it might not always be a multiple of 12 from monthly to yearly. So finde the index and add it accordingly
        // let _num_monthly_participant_equivalent = numSelectedParticipants / 12
        let selectedParticipantIndex = pricing.products[props.billingCycle]?.findIndex(
            (product) => product.participant_count === numSelectedParticipants
        );
        if (selectedParticipantIndex !== undefined && selectedParticipantIndex > -1) {
            let _num_monthly_participant_equivalent =
                pricing.products.monthly[selectedParticipantIndex].participant_count;
            setMonthlyAmount(
                getRoundedMonthlyPrice(pricing, _num_monthly_participant_equivalent, selectedCurrency, "monthly")
            );

            // set price id of respective plan
            let _productByBillingCycle = pricing.products[billingCycle];
            if (_productByBillingCycle) {
                let _priceIid = _productByBillingCycle[selectedParticipantIndex].prices.find(
                    (elem) => elem.currency === props.selectedCurrency
                )?.stripe_price_id;
                _priceIid && setCurrentPriceId(slicePriceId(_priceIid));
            }
        } else setCurrentPriceId("");
    }, [billingCycle, selectedCurrency, numSelectedParticipants]);

    const isStarter = pricing.features.unique_name.toLowerCase() === "starter";
    const isPro = pricing.features.unique_name.toLowerCase() === "pro";
    const isExpert = pricing.features.unique_name.toLowerCase() === "expert";

    return (
        <div
            onClick={() =>
                props.disabled && props.pricing.features.unique_name === "starter"
                    ? dispatch(
                        createMessage(
                            t(
                                "pricing.pricingCard.messages.starterOnly25recipients",
                                "The starter plan can only be used for our smallest monthly plan with 25 recipients."
                            ),
                            "info"
                        )
                    )
                    : undefined
            }
            className={clsx(classes.pricingCard, props.className, props.disabled ? classes.pricingCardDisabled : "")}
            style={props.featured ? { borderColor: theme.palette.vbBlue.main, borderWidth: 3 } : {}}
        >
            {props.featured && (
                <div
                    style={{
                        position: "absolute",
                        top: -14,
                        left: 36,
                        fontSize: 12,
                        backgroundColor: theme.palette.vbBlue.main,
                        color: "#ffffff",
                        borderRadius: 999,
                        padding: "4px 12px",
                    }}
                >
                    <p className="mb-0 user-select-none">
                        {t(
                            "pricing.pricingCard.mostPopular",
                            "MOST POPULAR"
                        )}</p>
                </div>
            )}
            {/* Plan Name */}
            <h4 className="ta-center mt-2 mb-3" style={{ fontWeight: 500, fontSize: 20 }}>
                {pricing.features.public_name}
            </h4>
            {/* Amount */}
            <div className="d-flex justify-content-center ta-center align-items-center">
                {props.billingCycle === "yearly" && (
                    <p className={clsx(classes.semiThick, "mr-3")}>
                        <del>
                            {amount && monthlyAmount ? (
                                <>
                                    {monthlyAmount.toLocaleString()}
                                    {selectedCurrency === "eur" && "€"}
                                    {selectedCurrency === "usd" && "$"}
                                </>
                            ) : (
                                ""
                            )}
                        </del>
                    </p>
                )}
                <p className={classes.superThick}>
                    {amount ? (
                        <>
                            {amount.toLocaleString()}
                            {selectedCurrency === "eur" && "€"}
                            {selectedCurrency === "usd" && "$"}
                        </>
                    ) : (
                        "-"
                    )}
                </p>
            </div>
            <p
                className={clsx(classes.thin, "text-center")}
                style={{
                    display: "block",
                    color: props.disabled ? theme.palette.grey[400] : theme.palette.grey[600],
                }}
            >
                {t(
                    "pricing.pricingCard.perMonth",
                    "per month"
                )}
            </p>
            {/* Select Button */}
            <div className="d-flex justify-content-center mb-4 mt-2">
                {/* hover listener when deactvated due to same plan */}
                <div>
                    <Button
                        variant={isStarter ? "outlined" : "contained"}
                        // Right side of OR statement: disable if the selected plan equals the current plan
                        disabled={props.disabled}
                        style={{ width: 150, boxShadow: "none" }}
                        className={isStarter ? classes.ctaButton : externalClasses.checkoutButtonRoot}
                        onClick={() =>
                        (window.parent.location.href = props.isCustom
                            ? "https://www.virtualbadge.io/contact"
                            : `${process.env.REACT_APP_ENV === "prod"
                                ? "https://www.virtualbadge.io/signup"
                                : "https://www.virtualbadge.io/test-page-virtualbadge-io"
                            }?plan=${currentPriceId}`)
                        }
                    ><b>
                            {props.isCustom ? t(
                                "pricing.pricingCard.contactUs",
                                "Contact us"
                            ) : t(
                                "pricing.pricingCard.startNow",
                                "Start now"
                            )}
                        </b>
                    </Button>
                    <p
                        className="text-center mt-2"
                        style={{
                            fontSize: 14,
                            color: props.disabled ? theme.palette.grey[300] : theme.palette.grey[600],
                        }}
                    >
                        {t(
                            "pricing.pricingCard.7dayFreeTrial",
                            "7 day free trial"
                        )}
                    </p>
                </div>
            </div>
            <div className={classes.horizonatlDivider} />
            {/* Features */}
            <div className={clsx("d-flex justify-content-around", classes.bitThick)}>
                <div className="text-center">
                    <p className={classes.lessMarginBottom}>
                        <b style={{ fontSize: "1.4rem" }}>
                            {/* &#8734; is unlimited / infinity icon */}
                            {pricing.features.max_badge_campaigns === -1 ? (
                                <span style={{ fontSize: "1.8rem" }}>&#8734;</span>
                            ) : (
                                pricing.features.max_badge_campaigns
                            )}
                        </b>
                    </p>
                    <p
                        style={{ marginTop: pricing.features.max_badge_campaigns === -1 ? "-0.81rem" : 0 }}
                        className={clsx(classes.lessMarginBottom, classes.smallerText)}
                    >
                        {t(
                            "pricing.pricingCard.certificate",
                            "Certificate"
                        )}
                        <br />
                        {pricing.features.max_badge_campaigns !== 1 ? "designs" : "design"}
                    </p>
                </div>
                <div className="text-center">
                    <p className={classes.lessMarginBottom}>
                        <b style={{ fontSize: "1.4rem" }}>{participantCountToDisplay}</b>
                    </p>
                    <p className={clsx(classes.lessMarginBottom, classes.smallerText)}>
                        {t(
                            "pricing.pricingCard.certificates",
                            "Certificates"
                        )}

                        <br />
                        {t(
                            "pricing.pricingCard.issued",
                            "issued"
                        )}
                    </p>
                </div>
            </div>
            <div className={classes.margin} />
            <div id="features">
                {isStarter && (
                    <div>
                        {[
                            t(
                                "pricing.pricingCard.features.customCertificateDesign",
                                "Custom Certificate Design"
                            ),
                            t(
                                "pricing.pricingCard.features.CertificateEmailSending",
                                "Certificate Email Sending"
                            ),
                            t(
                                "pricing.pricingCard.features.bulkRecipientUpload",
                                "Bulk Recipient Upload"
                            ), t(
                                "pricing.pricingCard.features.customSocialSharing",
                                "Custom Social Sharing"
                            ),
                        ].map((elem) => (
                            <FeatureItem name={elem} key={elem} starter disabled={props.disabled} />
                        ))}
                    </div>
                )}
                {isPro && (
                    <div>
                        {[
                            t(
                                "pricing.pricingCard.features.allFromStarter",
                                "All features from Starter"
                            ),
                            t(
                                "pricing.pricingCard.features.certificateValidation",
                                "Certificate validation"
                            ),
                            t(
                                "pricing.pricingCard.features.qrCodeCertificates",
                                "QR Code Certificates"
                            ),
                            t(
                                "pricing.pricingCard.features.zapier",
                                "Integrations via Zapier"
                            ),
                        ].map(
                            (elem) => (
                                <FeatureItem name={elem} key={elem} />
                            )
                        )}
                    </div>
                )}
                {isExpert && (
                    <div>
                        {[
                            t(
                                "pricing.pricingCard.features.allFromPro",
                                "All features from Pro"
                            ),
                            t(
                                "pricing.pricingCard.features.websiteValidationPageIntegration",
                                "Website Validation Page Integration"
                            ),
                            t(
                                "pricing.pricingCard.features.apiAccess",
                                "API access"
                            ),
                            t(
                                "pricing.pricingCard.features.unlimitedCourses",
                                "Unlimited Courses"
                            ),
                            t(
                                "pricing.pricingCard.features.accountSetupByCertificateExpert",
                                "Account Setup by Certificate Expert"
                            ),
                        ].map((elem) => (
                            <FeatureItem name={elem} key={elem} />
                        ))}
                    </div>
                )}
            </div>
        </div >
    );
};

export default PricingCard;
